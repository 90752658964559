package com.jfayz.appointments.ui.screens

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Card
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import coil3.compose.AsyncImage
import com.jfayz.appointments.data.Master
import com.jfayz.appointments.data.sampleData
import org.jetbrains.compose.ui.tooling.preview.Preview

@Composable
fun MasterSelectionScreen(masters: List<Master> = sampleData(), onSelected: (Master) -> Unit) {
    LazyColumn(modifier = Modifier.fillMaxSize()) {
        items(masters.size) { index ->
            val hairdresser = masters[index]
            Card(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(8.dp)
                    .clickable { onSelected(hairdresser) },
                shape = MaterialTheme.shapes.medium
            ) {
                Row(
                    modifier = Modifier
                        .padding(16.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    AsyncImage(
                        model = hairdresser.photoUrl,
                        contentDescription = null,
                        contentScale = ContentScale.Crop,
                        modifier = Modifier
                            .size(60.dp)
                            .clip(CircleShape),
                    )
                    Spacer(modifier = Modifier.width(16.dp))
                    Column {
                        Text(text = hairdresser.name, style = MaterialTheme.typography.titleMedium)
                        Text(text = hairdresser.serviceTypeName, style = MaterialTheme.typography.bodyMedium)
                    }
                }
            }
        }
    }
}

@Preview
@Composable
fun PreviewMasterSelectionScreen() {
    // Dummy data for preview
    val masters = sampleData()
    MasterSelectionScreen(masters = masters, onSelected = { /* Handle click */ })
}
