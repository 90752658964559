@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package appointments.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val back_button: StringResource by 
      lazy { init_back_button() }

  public val booking: StringResource by 
      lazy { init_booking() }

  public val confirmation: StringResource by 
      lazy { init_confirmation() }

  public val cyclone: StringResource by 
      lazy { init_cyclone() }

  public val masters: StringResource by 
      lazy { init_masters() }

  public val open_github: StringResource by 
      lazy { init_open_github() }

  public val run: StringResource by 
      lazy { init_run() }

  public val services: StringResource by 
      lazy { init_services() }

  public val settings: StringResource by 
      lazy { init_settings() }

  public val stop: StringResource by 
      lazy { init_stop() }

  public val theme: StringResource by 
      lazy { init_theme() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("back_button", CommonMainString0.back_button)
  map.put("booking", CommonMainString0.booking)
  map.put("confirmation", CommonMainString0.confirmation)
  map.put("cyclone", CommonMainString0.cyclone)
  map.put("masters", CommonMainString0.masters)
  map.put("open_github", CommonMainString0.open_github)
  map.put("run", CommonMainString0.run)
  map.put("services", CommonMainString0.services)
  map.put("settings", CommonMainString0.settings)
  map.put("stop", CommonMainString0.stop)
  map.put("theme", CommonMainString0.theme)
}

internal val Res.string.back_button: StringResource
  get() = CommonMainString0.back_button

private fun init_back_button(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back_button", "back_button",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/appointments.composeapp.generated.resources/values/strings.commonMain.cvr",
    10, 27),
    )
)

internal val Res.string.booking: StringResource
  get() = CommonMainString0.booking

private fun init_booking(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:booking", "booking",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/appointments.composeapp.generated.resources/values/strings.commonMain.cvr",
    38, 39),
    )
)

internal val Res.string.confirmation: StringResource
  get() = CommonMainString0.confirmation

private fun init_confirmation(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:confirmation", "confirmation",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/appointments.composeapp.generated.resources/values/strings.commonMain.cvr",
    78, 36),
    )
)

internal val Res.string.cyclone: StringResource
  get() = CommonMainString0.cyclone

private fun init_cyclone(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cyclone", "cyclone",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/appointments.composeapp.generated.resources/values/strings.commonMain.cvr",
    115, 27),
    )
)

internal val Res.string.masters: StringResource
  get() = CommonMainString0.masters

private fun init_masters(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:masters", "masters",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/appointments.composeapp.generated.resources/values/strings.commonMain.cvr",
    143, 35),
    )
)

internal val Res.string.open_github: StringResource
  get() = CommonMainString0.open_github

private fun init_open_github(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:open_github", "open_github",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/appointments.composeapp.generated.resources/values/strings.commonMain.cvr",
    179, 35),
    )
)

internal val Res.string.run: StringResource
  get() = CommonMainString0.run

private fun init_run(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:run", "run",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/appointments.composeapp.generated.resources/values/strings.commonMain.cvr",
    215, 15),
    )
)

internal val Res.string.services: StringResource
  get() = CommonMainString0.services

private fun init_services(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:services", "services",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/appointments.composeapp.generated.resources/values/strings.commonMain.cvr",
    231, 36),
    )
)

internal val Res.string.settings: StringResource
  get() = CommonMainString0.settings

private fun init_settings(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settings", "settings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/appointments.composeapp.generated.resources/values/strings.commonMain.cvr",
    268, 28),
    )
)

internal val Res.string.stop: StringResource
  get() = CommonMainString0.stop

private fun init_stop(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:stop", "stop",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/appointments.composeapp.generated.resources/values/strings.commonMain.cvr",
    297, 20),
    )
)

internal val Res.string.theme: StringResource
  get() = CommonMainString0.theme

private fun init_theme(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:theme", "theme",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/appointments.composeapp.generated.resources/values/strings.commonMain.cvr",
    318, 21),
    )
)
