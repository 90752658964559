package com.jfayz.appointments.data

data class Master(
    val photoUrl: String, val name: String, val serviceTypeName: String,
    val services: List<Service>,
)

data class Service(val name: String, val duration: Int)


fun sampleData(): List<Master> = listOf(
    Master(
        photoUrl = "https://example.com/hairdresser1.jpg",
        name = "Alice",
        serviceTypeName = "Hair Stylist",
        services = listOf(
            Service("Haircut", 30),
            Service("Coloring", 60),
            Service("Styling", 45),
        ),
    ),
    Master(
        photoUrl = "https://banner2.cleanpng.com/20210405/yep/transparent-chair-icon-hair-salon-icon-commerce-icon-606ac6fe9dc2b4.9666481816176104946462.jpg",
        name = "Bob",
        serviceTypeName = "Hairdresser",
        services = listOf(
            Service("Haircut", 30),
            Service("Haircut", 60),
        ),
    ),
    Master(
        photoUrl = "https://example.com/hairdresser3.jpg",
        name = "Charlie",
        serviceTypeName = "Hair Stylist",
        services = listOf(
            Service("Haircut", 30),
            Service("Coloring", 60),
            Service("Styling", 45),
        ),
    ),
    Master(
        photoUrl = "https://example.com/hairdresser4.jpg",
        name = "David",
        serviceTypeName = "Hair Stylist",
        services = listOf(
            Service("Haircut", 30),
            Service("Coloring", 60),
            Service("Styling", 45),
        ),
    ),
    Master(
        photoUrl = "https://example.com/hairdresser5.jpg",
        name = "Eve",
        serviceTypeName = "Hair Stylist",
        services = listOf(
            Service("Haircut", 30),
            Service("Coloring", 60),
            Service("Styling", 45),
        ),
    ),
)
