package com.jfayz.appointments.ui.screens

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.material3.Card
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.jfayz.appointments.data.Service
import org.jetbrains.compose.ui.tooling.preview.Preview

@Composable
fun ServiceListScreen(services: List<Service>, onServiceClick: (Service) -> Unit) {
    LazyColumn(modifier = Modifier.fillMaxSize()) {
        items(services.size) { index ->
            val service = services[index]
            Card(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(8.dp)
                    .clickable { onServiceClick(service) },
                shape = MaterialTheme.shapes.medium,
            ) {
                Row(
                    modifier = Modifier
                        .padding(16.dp)
                        .fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween,
                ) {
                    Text(text = service.name, style = MaterialTheme.typography.titleMedium)
                    Text(text = "${service.duration} min", style = MaterialTheme.typography.bodyMedium)
                }
            }
        }
    }
}

@Preview
@Composable
fun PreviewServiceListScreen() {
    val services = listOf(
        Service("Haircut", 30),
        Service("Coloring", 60),
        Service("Styling", 45),
    )
    ServiceListScreen(services = services, onServiceClick = { /* Handle click */ })
}
