package com.jfayz.appointments.data

import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.LocalTime
import kotlinx.serialization.Serializable

@Serializable
data class TimeSlot(
    val date: LocalDate,
    val time: LocalTime,
    val available: Boolean
) {
    constructor(dateTime: LocalDateTime, available: Boolean)
            : this(dateTime.date, dateTime.time, available)
}