package com.jfayz.appointments.ui.components.time

import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathFillType
import androidx.compose.ui.graphics.SolidColor
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.graphics.StrokeJoin
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.graphics.vector.path
import androidx.compose.ui.unit.dp

public val Arrow_back_ios: ImageVector
	get() {
		if (_Arrow_back_ios != null) {
			return _Arrow_back_ios!!
		}
		_Arrow_back_ios = ImageVector.Builder(
            name = "Arrow_back_ios",
            defaultWidth = 24.dp,
            defaultHeight = 24.dp,
            viewportWidth = 960f,
            viewportHeight = 960f
        ).apply {
			path(
    			fill = SolidColor(Color.Black),
    			fillAlpha = 1.0f,
    			stroke = null,
    			strokeAlpha = 1.0f,
    			strokeLineWidth = 1.0f,
    			strokeLineCap = StrokeCap.Butt,
    			strokeLineJoin = StrokeJoin.Miter,
    			strokeLineMiter = 1.0f,
    			pathFillType = PathFillType.NonZero
			) {
				moveTo(400f, 880f)
				lineTo(0f, 480f)
				lineToRelative(400f, -400f)
				lineToRelative(71f, 71f)
				lineToRelative(-329f, 329f)
				lineToRelative(329f, 329f)
				close()
			}
		}.build()
		return _Arrow_back_ios!!
	}

private var _Arrow_back_ios: ImageVector? = null
