package com.jfayz.appointments.ui.components.time

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.automirrored.filled.ArrowForward
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.LocalDate
import kotlinx.datetime.format
import kotlinx.datetime.format.FormatStringsInDatetimeFormats
import kotlinx.datetime.format.byUnicodePattern
import kotlinx.datetime.plus


@Composable
fun DatePicker(
    weekStartDate: LocalDate,
    selectedDate: LocalDate,
    onDateSelected: (LocalDate) -> Unit,
    onPreviousWeek: () -> Unit,
    onNextWeek: () -> Unit,
    showPrevious: Boolean,
    numberOfDaysToShow: Int
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween,
        modifier = Modifier
            .fillMaxWidth()
            .padding(bottom = 16.dp)
    ) {
        Text(text = "SELECT DATE", fontSize = 12.sp, color = Color.Gray)
        Row(verticalAlignment = Alignment.CenterVertically) {
            IconButton(onClick = onPreviousWeek, enabled = showPrevious) {
                Icon(
                    imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                    contentDescription = "Previous Week",
                    tint = if (showPrevious) Color.Black else Color.Gray // Disable tint if not showPrevious
                )
            }

            Text(
                text = weekStartDate.format(dateFormat),
                modifier = Modifier.padding(horizontal = 16.dp),
                fontSize = 16.sp
            )
            IconButton(onClick = onNextWeek) {
                Icon(imageVector = Icons.AutoMirrored.Filled.ArrowForward, contentDescription = "Next Week")
            }
        }
    }

    Row(modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.SpaceBetween) {
        for (offset in 0 until numberOfDaysToShow) {
            val date = weekStartDate.plus(DatePeriod(days = offset))
            DateChip(
                dayOfWeek = date.dayOfWeek.toString().substring(0, 3).uppercase(),
                dayOfMonth = date.dayOfMonth.toString(),
                selected = date == selectedDate,
                onClick = { onDateSelected(date) }
            )
        }
    }
}

private const val formatPattern = "MM yyyy"

@OptIn(FormatStringsInDatetimeFormats::class)
private val dateFormat = LocalDate.Format {
    byUnicodePattern(formatPattern)
}
