package com.jfayz.appointments.ui.components.time

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import kotlinx.datetime.LocalTime
import kotlinx.datetime.format
import kotlinx.datetime.format.FormatStringsInDatetimeFormats
import kotlinx.datetime.format.byUnicodePattern

@Composable
fun DateChip(dayOfWeek: String, dayOfMonth: String, selected: Boolean, onClick: () -> Unit) {
    val backgroundColor = if (selected) Color(0xFF007AFF) else Color.LightGray // Blue if selected, gray otherwise
    val textColor = if (selected) Color.White else Color.Black
    val shape = RoundedCornerShape(20.dp)


    Surface(
        modifier = Modifier
            .clickable(onClick = onClick)
            .padding(4.dp),
        shape = shape,
        color = backgroundColor,


        ) {
        Column(
            modifier = Modifier
                .padding(horizontal = 12.dp, vertical = 8.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            Text(text = dayOfWeek, fontSize = 12.sp, color = textColor)
            Text(text = dayOfMonth, fontSize = 14.sp, color = textColor)
        }
    }
}


private const val formatPattern = "H:mm"

@OptIn(FormatStringsInDatetimeFormats::class)
private val timeFormat = LocalTime.Format {
    byUnicodePattern(formatPattern)
}

@Composable
fun TimeChip(time: LocalTime, selected: Boolean, disabled: Boolean, onClick: () -> Unit) {
    val backgroundColor =
        if (selected) Color(0xFF007AFF) else if (disabled) Color.LightGray.copy(alpha = 0.5f) else Color.LightGray

    val textColor = if (selected) Color.White else if (disabled) Color.Gray else Color.Black

    val formattedTime = time.format(timeFormat)

    Surface(
        color = backgroundColor,
        shape = RoundedCornerShape(20),
        modifier = Modifier
            .padding(4.dp)
            .clickable(enabled = !disabled, onClick = onClick)
            .border(
                width = if (selected) 2.dp else 1.dp, // bold border when selected
                color = if (selected) Color.Black else Color.LightGray,
                shape = RoundedCornerShape(20)
            )
    ) {
        Box(
            modifier = Modifier.padding(horizontal = 16.dp, vertical = 8.dp),
        ) {
            Text(
                text = formattedTime,
                color = textColor,
                textAlign = TextAlign.Center
            )

            if (disabled) { // Apply strikethrough when disabled
                Box(
                    modifier = Modifier
//                        .fillMaxWidth()
                        .height(1.dp)
                        .background(Color.Gray)
                        .align(Alignment.Center) // Center strikethrough
                )
            }
        }
    }

}