package com.jfayz.appointments

import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.safeDrawing
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
import androidx.navigation.compose.currentBackStackEntryAsState
import androidx.navigation.compose.rememberNavController
import com.jfayz.appointments.theme.AppTheme
import com.jfayz.appointments.ui.screens.main.AppointmentBookingScreen
import com.jfayz.appointments.ui.screens.main.AppointmentBookingViewModel


@Composable
fun AppointmentApp(
    modifier: Modifier = Modifier
        .fillMaxSize()
        .windowInsetsPadding(WindowInsets.safeDrawing)
        .padding(16.dp),
) = AppTheme {
    Navigation()
}
