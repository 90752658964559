package com.jfayz.appointments.ui.screens.main

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.lifecycle.viewmodel.compose.viewModel
import com.jfayz.appointments.ui.components.time.DatePicker
import com.jfayz.appointments.ui.components.time.TimeChip

@Composable
fun AppointmentBookingScreen(
    modifier: Modifier,
    viewModel: AppointmentBookingViewModel = viewModel { AppointmentBookingViewModel() },
) {
    val uiState by viewModel.uiState.collectAsState()

    BoxWithConstraints(modifier = modifier) {
        val maxWidth = maxWidth

        val numberOfDaysToShow = remember(maxWidth) {
            val dateChipWidth = 64.dp
            val horizontalPadding = 16.dp
            viewModel.calculateNumberOfDaysToShow(maxWidth, dateChipWidth, horizontalPadding)
        }

        if (uiState.isLoading) {
            Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
                CircularProgressIndicator()
            }
        } else {
            Column(
                modifier = Modifier.fillMaxSize(),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                DatePicker(
                    weekStartDate = uiState.weekStartDate,
                    selectedDate = uiState.selectedDate,
                    onDateSelected = viewModel::onDateSelected,
                    onPreviousWeek = viewModel::toPreviousTimePeriod,
                    onNextWeek = viewModel::toNextTimePeriod,
                    showPrevious = uiState.showPrevious,
                    numberOfDaysToShow = numberOfDaysToShow,
                )

                Spacer(modifier = Modifier.height(16.dp))

                Text(text = "SELECT TIME", fontSize = 12.sp, color = Color.Gray)
                Spacer(modifier = Modifier.height(8.dp))

                if (!uiState.hasSlotsOnSelectedDate()) {
                    Text("No available time slots for the selected date.")
                    return@BoxWithConstraints
                }

                val slots = remember(uiState.selectedDate) {
                    uiState.timeSlots.filter { it.date == uiState.selectedDate }
                }

                val minColumnWidth = 100.dp

                LazyVerticalGrid(
                    columns = GridCells.Adaptive(minColumnWidth),
                    modifier = Modifier.fillMaxWidth().weight(2f),
                    verticalArrangement = Arrangement.spacedBy(8.dp),
                    horizontalArrangement = Arrangement.spacedBy(8.dp, Alignment.CenterHorizontally),
                ) {
                    items(slots.size) { index ->
                        val timeSlot = slots[index]
                        TimeChip(
                            time = timeSlot.time,
                            selected = uiState.selectedTime == timeSlot.time,
                            disabled = !timeSlot.available,
                            onClick = { viewModel.onTimeSelected(timeSlot.time) },
                        )
                    }
                }

                if (uiState.selectedTime != null) {
                    Spacer(modifier = Modifier.weight(0.1f))
                    Button(
                        onClick = { /* TODO: Handle appointment booking */ },
                        modifier = Modifier.fillMaxWidth().padding(16.dp),
                        colors = ButtonDefaults.buttonColors(contentColor = Color(0xFF007AFF)),
                    ) {
                        Text("Book Appointment", color = Color.White)
                    }
                }
            }
        }
    }
}

//@Composable
//fun <ITEMS> ColumnMajorGrid(
//    items: List<ITEMS>,
//    numColumns: Int,
//    itemContent: @Composable (ITEMS) -> Unit,
//) {
//    // Calculate the number of rows based on the number of items and columns
//    val numRows = (items.size + numColumns - 1) / numColumns
//
//    LazyVerticalGrid(
//        columns = GridCells.Fixed(numColumns),
//        modifier = Modifier.fillMaxSize(),
//        contentPadding = PaddingValues(16.dp),
//        verticalArrangement = Arrangement.spacedBy(8.dp),
//        horizontalArrangement = Arrangement.spacedBy(8.dp),
//    ) {
//        items(count = items.size) { index ->
//            // Calculate the row-major index to match the column-major order
//            val rowIndex = index / numColumns
//            val columnIndex = index % numColumns
//            val columnMajorIndex = columnIndex * numRows + rowIndex
//
//            // Display the item based on the new column-major index
//            itemContent(items[columnMajorIndex])
//        }
//    }
//}
