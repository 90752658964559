package com.jfayz.appointments.ui.screens.main

import androidx.compose.ui.unit.Dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.jfayz.appointments.data.TimeSlot
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.datetime.Clock
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.minus
import kotlinx.datetime.plus
import kotlinx.datetime.toLocalDateTime

class AppointmentBookingViewModel : ViewModel() {
    private val _uiState = MutableStateFlow(AppointmentBookingUiState())
    val uiState: StateFlow<AppointmentBookingUiState> = _uiState.asStateFlow()

    fun calculateNumberOfDaysToShow(availableWidth: Dp, dateChipWidth: Dp, horizontalPadding: Dp): Int {
        val numberOfDays = ((availableWidth - horizontalPadding) / dateChipWidth).toInt().coerceIn(1, 7)
        _uiState.update { currentState ->
            currentState.copy(numberOfDaysToShow = numberOfDays)
        }
        return numberOfDays
    }

    fun onDateSelected(selectedDate: LocalDate) {
        val selectedDateSlots = uiState.value.timeSlots
            .filter { it.date == selectedDate }
//            .partition { it.time.hour < 12 }

        _uiState.update { currentState ->
            currentState.copy(
                selectedDate = selectedDate,
                selectedTime = null,
                selectedDateSlots = selectedDateSlots,
            )
        }
    }

    fun onTimeSelected(selectedTime: LocalTime) = _uiState.update { currentState ->
        currentState.copy(selectedTime = selectedTime)
    }

    fun toPreviousTimePeriod() = newWeekStartDate { it.weekStartDate.minus(DatePeriod(days = it.numberOfDaysToShow)) }

    fun toNextTimePeriod() = newWeekStartDate { it.weekStartDate.plus(DatePeriod(days = it.numberOfDaysToShow)) }

    private fun newWeekStartDate(newDateF: (AppointmentBookingUiState) -> LocalDate) {
        _uiState.update { currentState ->
            val newDate = newDateF(currentState)
            currentState.copy(
                weekStartDate = newDate,
                selectedDate = newDate,
                selectedTime = null,
                showPrevious = newDate.isAfter(getCurrentDate())
            )
        }
    }

    private fun setTimeslots(slots: List<TimeSlot>) = _uiState.update { currentState ->
        currentState.copy(timeSlots = slots)
    }


    init {
        viewModelScope.launch(Dispatchers.Default) {
            getFreeTimeSlots()
        }

        viewModelScope.launch {
            uiState.collectLatest {
                println("uiState: $it")
            }
        }
    }

    suspend fun getFreeTimeSlots() {
        _uiState.update { it.copy(isLoading = true) }
        delay(1000) // Simulate network request
//        val response = NetworkClient.calendarApi.getAvailableTime()
//        if (!response.isSuccessful) {
//            println("Error!!!! ${response.errorBody()}")
//            return
//        }
//        val timeSlots = response.body() ?: emptyList()
        val timeSlots = getSampleData()

        println("currentTimeSlots: $timeSlots")

        withContext(Dispatchers.Main) {
            setTimeslots(timeSlots)
            onDateSelected(getCurrentDate())
            _uiState.update { it.copy(isLoading = false) }
        }
    }
}

fun LocalDate.plusWeeks(i: Int) = plus(DatePeriod(days = i * 7))
fun LocalDate.minusWeeks(i: Int) = minus(DatePeriod(days = i * 7))
fun LocalDate.isAfter(other: LocalDate): Boolean = this > other

private fun getCurrentDate(): LocalDate {
    val currentMoment: Instant = Clock.System.now()
    val datetimeInUtc: LocalDate = currentMoment.toLocalDateTime(TimeZone.UTC).date
    return datetimeInUtc;
}

private fun getCurrentTime(): LocalTime {
    val currentMoment: Instant = Clock.System.now()
    val datetimeInUtc: LocalTime = currentMoment.toLocalDateTime(TimeZone.UTC).time
    return datetimeInUtc
}

data class AppointmentBookingUiState(
    val weekStartDate: LocalDate = getCurrentDate(),
    val selectedDate: LocalDate = getCurrentDate(),
    val selectedTime: LocalTime? = null,
    val selectedDateSlots: List<TimeSlot> = emptyList(),
    val timeSlots: List<TimeSlot> = emptyList(),
    val numberOfDaysToShow: Int = 7,
    val showPrevious: Boolean = false,
    val isLoading: Boolean = true
) {
    fun hasSlotsOnSelectedDate() = selectedDateSlots.isNotEmpty()
}

private fun getSampleData(): List<TimeSlot> {
    val timeSlots = mutableListOf<TimeSlot>()
    val today = getCurrentDate()

    for (i in 0..7) {
        val date = today.plus(DatePeriod(days = i))

        for (hour in 9..17) {
            timeSlots.add(TimeSlot(date, LocalTime(hour, 0), true))
            timeSlots.add(TimeSlot(date, LocalTime(hour, 30), true))
        }
    }
    return timeSlots
}
