package com.jfayz.appointments

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBar
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import androidx.navigation.compose.NavHost
import androidx.navigation.compose.composable
import androidx.navigation.compose.currentBackStackEntryAsState
import androidx.navigation.compose.rememberNavController
import appointments.composeapp.generated.resources.Res
import appointments.composeapp.generated.resources.back_button
import appointments.composeapp.generated.resources.booking
import appointments.composeapp.generated.resources.confirmation
import appointments.composeapp.generated.resources.masters
import appointments.composeapp.generated.resources.services
import com.jfayz.appointments.data.Master
import com.jfayz.appointments.ui.components.time.Arrow_back_ios
import com.jfayz.appointments.ui.screens.MasterSelectionScreen
import com.jfayz.appointments.ui.screens.ServiceListScreen
import com.jfayz.appointments.ui.screens.main.AppointmentBookingScreen
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.stringResource


@Composable
fun Navigation(
    navController: NavHostController = rememberNavController(),
    navigationModel: NavigationViewModel = viewModel { NavigationViewModel() },
) {
    // Get current back stack entry
    val backStackEntry by navController.currentBackStackEntryAsState()
    // Get the name of the current screen
    val currentScreen = AppScreen.valueOf(
        backStackEntry?.destination?.route ?: AppScreen.Masters.name,
    )

    Scaffold(
        topBar = {
            AppBar(
                currentScreen = currentScreen,
                canNavigateBack = navController.previousBackStackEntry != null,
                navigateUp = { navController.navigateUp() },
            )
        },
    ) { innerPadding ->
        val uiState by navigationModel.navigationState.collectAsState()

        NavHost(
            navController = navController,
            startDestination = AppScreen.Masters.name,
            modifier = Modifier
                .fillMaxSize()
                .padding(innerPadding)
                .padding(horizontal = 16.dp),
        ) {
            composable(route = AppScreen.Masters.name) {
                MasterSelectionScreen(
//                    masters = DataSource.masters,
                    onSelected = { master: Master ->
                        navController.navigate(AppScreen.Services.name)
                        navigationModel.setMaster(master)
                    },
                )
                // Adaptive
//                SelectionScreen(
//                    onServiceClick = { navController.navigate(AppScreen.Booking.name) },
//                )
            }
            composable(route = AppScreen.Services.name) {
                uiState.selectedMaster ?: return@composable
                ServiceListScreen(
                    services = uiState.selectedMaster!!.services,
                    onServiceClick = { navController.navigate(AppScreen.Booking.name) },
                )
            }
            composable(route = AppScreen.Booking.name) {
                AppointmentBookingScreen(
                    Modifier.fillMaxSize(),
                )
            }
        }
    }
}

/**
 * Composable that displays the topBar and displays back button if back navigation is possible.
 */
@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun AppBar(
    currentScreen: AppScreen,
    canNavigateBack: Boolean,
    navigateUp: () -> Unit,
    modifier: Modifier = Modifier,
) {
    TopAppBar(
        title = { Text(stringResource(currentScreen.title)) },
        colors = TopAppBarDefaults.mediumTopAppBarColors(
            containerColor = MaterialTheme.colorScheme.primaryContainer,
        ),
        modifier = modifier,
        navigationIcon = {
            if (canNavigateBack) {
                IconButton(onClick = navigateUp) {
                    Icon(
                        imageVector = Arrow_back_ios,//Icons.AutoMirrored.Filled.ArrowBack,
                        contentDescription = stringResource(Res.string.back_button),
                    )
                }
            }
        },
    )
}


/**
 * enum values that represent the screens in the app
 */
enum class AppScreen(val title: StringResource) {
    Masters(title = Res.string.masters),
    Services(title = Res.string.services),
    Booking(title = Res.string.booking),
    Confirmation(title = Res.string.confirmation)
}

class NavigationViewModel : ViewModel() {
    private val _navigationState = MutableStateFlow(NavigationState())
    val navigationState: StateFlow<NavigationState> = _navigationState.asStateFlow()

    fun setMaster(master: Master) {
        _navigationState.update {
            it.copy(selectedMaster = master)
        }
    }
}

data class NavigationState(
    val currentScreen: AppScreen = AppScreen.Masters,
    val selectedMaster: Master? = null,
)
